<template>
  <div class="w-full p-2">
    <Spinner class="m-auto h-8 w-8 animate-spin text-blue-ribbon-600" />
    <!-- An alternative pulsing skeleton that looks worse -->
    <!-- <div class="mx-auto mb-10 mt-10 h-[30rem] max-w-7xl px-4 sm:px-6 lg:px-8">
    <div class="h-full w-full animate-pulse rounded-md bg-gray-400" /> -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Spinner from './Spinner.vue'

export default defineComponent({
  components: {
    Spinner
  }
})
</script>
