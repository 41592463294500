<template>
  <router-link
    :to="{ name: 'For garages' }"
    class="fixed right-0 top-1/2 z-30 hidden rounded-l-full bg-blue-ribbon-500 px-4 py-2 text-white shadow hover:cursor-pointer hover:bg-blue-ribbon-600 sm:block"
  >
    <div class="text-center">Are you <br />a garage?</div>
  </router-link>
  <Hero />
  <Features />
  <Promises />
  <div id="bottom-section">
    <template v-if="showBottomSection">
      <CallToAction />
      <Testimonials />
    </template>
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from 'vue'
import { useHead } from '@vueuse/head'

import Hero from './../components/Hero.vue'
import Features from './../components/Features.vue'
import Promises from './../components/Promises.vue'
import Loader from './../components/Loader.vue'

const CallToAction = defineAsyncComponent({
  loader: () => import('./../components/CallToAction.vue'),
  loadingComponent: Loader
})

const Testimonials = defineAsyncComponent(
  () => import('./../components/Testimonials.vue')
)

export default defineComponent({
  setup() {
    useHead({
      title: 'Find your nearest garage - GaragesNear.me',
      meta: [
        {
          name: 'og:title',
          content: 'Find your nearest garage - GaragesNear.me'
        },
        {
          name: 'description',
          content: 'Compare and book garage services online'
        },
        {
          name: 'og:description',
          content: 'Compare and book garage services online'
        },
        {
          name: 'og:image',
          content: '/open-graph/hero.webp'
        },
        {
          name: 'og:image:alt',
          content: 'GaragesNear.me banner'
        },
        {
          name: 'og:image:width',
          content: 1280
        },
        {
          name: 'og:image:height',
          content: 576
        }
      ]
    })
  },
  data() {
    return {
      showBottomSection: false,
      observer: null as IntersectionObserver
    }
  },
  components: {
    Hero,
    Features,
    CallToAction,
    Testimonials,
    Promises
  },
  mounted() {
    if (!window.IntersectionObserver) {
      this.showBottomSection = true
      return
    }

    const bottomSection: Element = document.querySelector('#bottom-section')

    if (bottomSection) {
      this.observer = new IntersectionObserver((entries) => {
        const isBottomSectionIntersecting = entries[0].isIntersecting
        if (isBottomSectionIntersecting) {
          this.showBottomSection = isBottomSectionIntersecting
          this.observer.unobserve(bottomSection)
        }
      })
      this.observer.observe(bottomSection)
    }
  }
})
</script>
