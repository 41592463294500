<template>
  <div class="bg-white py-12">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="mt-10">
        <dl
          class="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0"
        >
          <div v-for="feature in features" :key="feature.name" class="relative">
            <dt>
              <div
                class="absolute flex h-12 w-12 items-center justify-center rounded-md bg-blue-ribbon-500 text-white"
              >
                <component
                  :is="feature.icon"
                  class="h-6 w-6"
                  aria-hidden="true"
                />
              </div>
              <p class="ml-16 text-lg font-medium leading-6 text-gray-900">
                {{ feature.name }}
              </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  CurrencyPoundIcon,
  LocationMarkerIcon,
  ThumbUpIcon,
  LockClosedIcon
} from '@heroicons/vue/outline'

const features: { name: string; description: string; icon: any }[] = [
  {
    name: 'Local to you',
    description:
      'Search our approved garage network to find your nearest garage; our service is completely free to use, there are no hidden charges.',
    icon: LocationMarkerIcon
  },
  {
    name: 'Compare garage prices',
    description:
      'Compare the prices and services from your local garages to find the perfect garage for your needs.',
    icon: CurrencyPoundIcon
  },
  {
    name: 'Quick and simple',
    description:
      'Our quick and simple booking process will have your garage slot booked with just a few clicks.',
    icon: ThumbUpIcon
  },
  {
    name: 'Binding price',
    description:
      'Our data driven booking process allows for binding prices, so what you’re quoted is what you’ll pay at the garage.',
    icon: LockClosedIcon
  }
]
</script>
